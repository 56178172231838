import Button from '@atoms/Button';
import Image from '@atoms/Image';
import Link from '@atoms/Link';
import Text from '@atoms/Text';
import React from 'react';

const SignupStickyView = ({ showSignUp }) => {
    let rotateClass = 'rotate-90';
    if (typeof window !== 'undefined') {
        rotateClass = window.scrollY < 80 ? 'rotate-90' : '-rotate-90';
    }
    return (
        <div
            className={`${showSignUp} z-100 sticky bottom-0 bg-basicWhite py-6 px-8 w-screen text-center md:hidden transition-all`}
        >
            <Link
                href="#signUpForm"
                weight="bold"
                children={
                    <Button
                        buttonType="submit"
                        design="standard"
                        type="primary"
                        className="justify-center w-full p-4 text-center rounded-md"
                        icon="yes"
                        children={
                            <>
                                <Text
                                    className="font-bold"
                                    content={'Sign Up'}
                                />
                                <Image
                                    className={`${rotateClass} ml-2 rotate-180`}
                                    src={'/images/Arrow-Right.svg'}
                                />
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default SignupStickyView;
